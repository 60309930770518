import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [~5,[2],[3]],
		"/(guest)/login": [~10,[4]],
		"/(app)/(auth)/me": [~6,[2],[3]],
		"/prefs": [~12],
		"/(guest)/register": [11,[4]],
		"/(app)/wiki/[file]": [~9,[2],[3]],
		"/(app)/(auth)/write": [~7],
		"/(app)/[...path]": [~8,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';